import React, { useState } from 'react';
import { FMCard, FMCarousel } from '@own/fds-react';
import { DisclaimerList } from '../../components/common/disclaimer-list/disclaimer-list';
import { useProcessedFMHomeContent } from './hooks/use-processed-fm-home-content';
import { FMCardProps } from '@own/fds-react/dist/types/fm-card.types';
import serverSideService from '../../services/server-side-service/server-side-service';
import FMLinksList from '../../components/common/fm-links-list/fm-links-list';
import FMSignInSbv from '../../components/common/fm-signin-sbv/fm-signin-sbv';
import FMMasthead from '../../components/common/fm-masthead/fm-masthead';
import SearchByVehicleDrawer from '../../components/sections/search-by-vehicle-drawer/search-by-vehicle-drawer';
import { useSearchContent } from '../../components/sections/search-bar/hooks/use-search-content';
import { useFMMastheadContent } from './hooks/use-fm-masthead-content';
import FMOverlayBanner from '../../components/common/fm-overlay-banner/fm-overlay-banner';
import { useAnalytics } from '../../hooks/use-analytics';
import { NewAnalyticsService } from '../../services/new-analytics-service/new-analytics-service';
import '../fm-home-page/fm-home-page.scss';
import { useFMTabbedFaqsContent } from './hooks/use-tabbed-faqs-content';
import FMTabbedAccordions from '../../components/common/fm-tabbed-accordions/fm-tabbed-accordions';
import FMEscapeHatch from '../../components/common/fm-escape-hatch/fm-escape-hatch';
import { useFMEscapeHatchContent } from './hooks/use-fm-escape-hatch-content';
import useFooterDisplay from '../../hooks/use-footer-display';
import useSeoConfig from '../../hooks/use-seo-config';

export const FMHomePage = () => {
    const page = 'Home Page 3.0';
    const [showDraw, setShowDraw] = useState(false);
    const getFmaAuthenticated = () => {
        if (serverSideService.isClientSide()) {
            return sessionStorage.getItem('fmaAuthenticated') == 'true';
        }
        return false;
    };
    const authenticationState = getFmaAuthenticated();
    const [fireEvents] = useAnalytics();
    useAnalytics(
        ['target-trigger-view', 'smash-owner-home'],
        'target-owner-home'
    );
    const {
        processedSignInSearchByVehicleContent,
        processedFMCarousel,
        processedFMOverlayBanner,
        processedDisclaimers,
    } = useProcessedFMHomeContent();

    const fmMastheadContent = useFMMastheadContent();
    const searchContent = useSearchContent();
    const escapeHatchContent = useFMEscapeHatchContent();

    const handleFMCardClick = async (item: FMCardProps, eventName: string) => {
        if (!item.title) return;
        const ctaTitle = item.title
            .toLowerCase()
            ?.replace(/[^a-zA-Z0-9 ]/g, ' ')
            ?.trim();
        const referralExit = await new NewAnalyticsService().findMatchingReferralExit(
            item.ctaHref || ''
        );
        if (referralExit) {
            NewAnalyticsService.fireReferralExitsEvent(
                fireEvents,
                referralExit
            );
        } else if (eventName) {
            fireEvents(
                eventName,
                undefined,
                {
                    smashHomeClick: {
                        cardName: ctaTitle,
                    },
                },
                false
            );
        }
    };

    const handleFMOverlayBannerClickEvent = async (
        ctaLabel: string,
        eventName: string
    ) => {
        const title =
            typeof processedFMOverlayBanner?.title === 'object'
                ? (processedFMOverlayBanner?.title as any)?.props?.children
                : processedFMOverlayBanner?.title;

        const bannerName = title
            ?.replace(/\[disclaimer\]/g, '')
            ?.replace(/[^a-zA-Z0-9 ]/g, ' ')
            ?.replace(/\s+/g, ' ')
            ?.trim();
        const ctaType = ctaLabel
            ?.replace(/[^a-zA-Z0-9 ]/g, ' ')
            ?.replace(/\s+/g, ' ')
            ?.trim();

        const referralExit = await new NewAnalyticsService().findMatchingReferralExit(
            ctaLabel
        );

        if (referralExit) {
            NewAnalyticsService.fireReferralExitsEvent(
                fireEvents,
                referralExit
            );
        } else if (eventName) {
            fireEvents(eventName, undefined, {
                ctaType,
                bannerName,
            });
        }
    };
    useFooterDisplay([]);
    useSeoConfig('home');
    const fmFaqsContent = useFMTabbedFaqsContent();
    return (
        <div className="shared-view smash-home">
            {processedSignInSearchByVehicleContent && (
                <SearchByVehicleDrawer
                    showDraw={showDraw}
                    setShowDraw={setShowDraw}
                    signInSearchByVehicleContent={
                        processedSignInSearchByVehicleContent
                    }
                    page={page}
                    authenticationState={authenticationState}
                />
            )}
            <FMMasthead
                mastheadContent={fmMastheadContent}
                searchContent={searchContent}
            />
            {processedSignInSearchByVehicleContent && (
                <FMSignInSbv
                    signInSearchByVehicleContent={
                        processedSignInSearchByVehicleContent
                    }
                    authenticationState={authenticationState}
                    setShowDraw={setShowDraw}
                />
            )}
            <FMLinksList />
            {processedFMCarousel &&
                !processedFMCarousel?.hide &&
                processedFMCarousel.fmCards?.length > 0 && (
                    <>
                        <div className="fm-home-featured-articles-carousel-header">
                            {processedFMCarousel.title && (
                                <h2 className="fm-home-featured-articles-carousel__title">
                                    {processedFMCarousel.title}
                                </h2>
                            )}
                        </div>
                        <FMCarousel
                            rightButtonAriaLabel={
                                processedFMCarousel?.rightButtonAriaLabel
                            }
                            leftButtonAriaLabel={
                                processedFMCarousel?.leftButtonAriaLabel
                            }
                            items={processedFMCarousel?.fmCards}
                            render={(item: any) => (
                                <FMCard
                                    {...item}
                                    borderless={true}
                                    imageSrc={`${process.env.REACT_APP_AEM_BASE_URL}${item.imagePath}`}
                                    imageAlt={item.imageAltText}
                                    ctaLabel={item.cta1Label}
                                    ctaHref={item.cta1Path}
                                    ctaType={'primary'}
                                    ctaTheme={'light'}
                                    ctaOnClick={() =>
                                        handleFMCardClick(
                                            item,
                                            'fm-home-page-carousel-cta'
                                        )
                                    }
                                    cta2Label={item.cta2Label}
                                    cta2Href={item.cta2Path}
                                    cta2Type={'secondary'}
                                    cta2Theme={'light'}
                                    cta2OnClick={() =>
                                        handleFMCardClick(
                                            item,
                                            'fm-home-page-carousel-cta'
                                        )
                                    }
                                >
                                    {item?.children}
                                </FMCard>
                            )}
                        />
                    </>
                )}
            {processedFMOverlayBanner && (
                <FMOverlayBanner
                    {...processedFMOverlayBanner}
                    onClickCta1={() =>
                        handleFMOverlayBannerClickEvent(
                            processedFMOverlayBanner?.cta1Label,
                            'fm-overlay-banner-cta'
                        )
                    }
                    onClickCta2={() =>
                        handleFMOverlayBannerClickEvent(
                            processedFMOverlayBanner?.cta2Label,
                            'fm-overlay-banner-cta'
                        )
                    }
                />
            )}
            <FMTabbedAccordions content={fmFaqsContent} />
            <FMEscapeHatch content={escapeHatchContent} />
            {processedDisclaimers.length > 0 && (
                <DisclaimerList disclaimers={processedDisclaimers} />
            )}
        </div>
    );
};

export default FMHomePage;
