import React from 'react';
import './fm-masthead.scss';
import FMTrendingTopics from '../fm-trending-topics/fm-trending-topics';
import { FMMastheadContent } from '../../../views/fm-home-page/hooks/use-fm-masthead-content';
import PreImagineSearchBar from '../../sections/search-bar/pre-imagine-search-bar/pre-imagine-search-bar';
import { SearchContentProps } from '../../sections/search-bar/hooks/use-search-content';
interface Props {
    mastheadContent: FMMastheadContent;
    searchContent: SearchContentProps | null;
}
const FMMasthead = (props: Props) => {
    return (
        <>
            {props.mastheadContent?.popularLinksTitle && (
                <div className="fm-masthead">
                    <img
                        className="fm-masthead-image"
                        src={`${process.env.REACT_APP_AEM_BASE_URL}${props.mastheadContent.mastheadImage}`}
                        alt="masthead"
                        loading="lazy"
                        aria-hidden="true"
                    />

                    <div className="fm-masthead-overlay">
                        <div className="fm-masthead-content">
                            <h1 className="fm-masthead-search-title">
                                {props.mastheadContent.popularLinksTitle}
                            </h1>
                            {props.searchContent && (
                                <PreImagineSearchBar
                                    searchContent={props.searchContent}
                                    placeholder={
                                        props.mastheadContent
                                            .popularLinksSubTitle
                                    }
                                    hideSearchBarTitle={true}
                                />
                            )}
                            <FMTrendingTopics
                                popularLinks={
                                    props.mastheadContent?.popularLinksItems
                                }
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default FMMasthead;
